/**
 |-----------------------------------------------------------------------------
 | custom/menu.js
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

/*
 |
 | Some description...
 |
 */

$('[data-js="open-menu"]').click(function() {

	// Toggle classes
	$('body').toggleClass('is-blurred');

	$('.l-site-nav').toggleClass('is-open');

});


/*
 |
 | Some description...
 |
 */

$('[data-js="close-menu"]').click(function() {

	// Toggle classes
	$('body').toggleClass('is-blurred');

	$('.l-site-nav').toggleClass('is-open');

});


/*
 |
 | Some description...
 |
 */

$(document).keyup(function(e) {

	if ((e.keyCode === 27) && ($('body').hasClass('is-blurred'))) {             // Escape key

		// Toggle classes
		$('body').toggleClass('is-blurred');

		$('.l-site-nav').toggleClass('is-open');

	}

});


/*
 |
 | Some description...
 |
 */

$('.c-header-menu .c-header-menu__item a').click(function(e) {

	if ($(this).attr('href') === '#') {

		// Prevent default
		e.preventDefault();

		if ($(this).siblings('button').hasClass('is-open')) {

			// Remove class 'is-open'
			$('.js-drop-down-1').removeClass('is-open');
			$('.js-drop-down-2').removeClass('is-open');

			// Collapse drop-down
			$(this).siblings('.c-header-menu--lvl-1').slideUp(400, 'swing');
			$('.c-header-menu--lvl-2').slideUp(400, 'swing');

		} else {

			// Remove class 'is-open'
			$('.js-drop-down-1').removeClass('is-open');
			$('.js-drop-down-2').removeClass('is-open');

			// Collapse all drop-downs
			$('.c-header-menu--lvl-1').slideUp(400, 'swing');
			$('.c-header-menu--lvl-2').slideUp(400, 'swing');

			// Add class 'is-open'
			$(this).siblings('button').addClass('is-open');

			// Expand drop-down
			$(this).siblings('.c-header-menu--lvl-1').slideDown(400, 'swing');

		}

	}

});


/*
 |
 | Some description...
 |
 */

$('.js-drop-down-1').click(function() {

	if ($(this).hasClass('is-open')) {

		// Remove class 'is-open'
		$('.js-drop-down-1').removeClass('is-open');
		$('.js-drop-down-2').removeClass('is-open');

		// Collapse drop-down
		$(this).next('.c-header-menu--lvl-1').slideUp(400, 'swing');
		$('.c-header-menu--lvl-2').slideUp(400, 'swing');

	} else {

		// Remove class 'is-open'
		$('.js-drop-down-1').removeClass('is-open');
		$('.js-drop-down-2').removeClass('is-open');

		// Collapse all drop-downs
		$('.c-header-menu--lvl-1').slideUp(400, 'swing');
		$('.c-header-menu--lvl-2').slideUp(400, 'swing');

		// Add class 'is-open'
		$(this).addClass('is-open');

		// Expand drop-down
		$(this).next('.c-header-menu--lvl-1').slideDown(400, 'swing');

	}

});


/*
 |
 | Some description...
 |
 */

$('.js-drop-down-2').click(function() {

	if ($(this).hasClass('is-open')) {

		// Remove class 'is-open'
		$('.js-drop-down-2').removeClass('is-open');

		// Collapse drop-down
		$(this).next('.c-header-menu--lvl-2').slideUp(400, 'swing');

	} else {

		// Remove class 'is-open'
		$('.js-drop-down-2').removeClass('is-open');

		// Collapse all drop-downs
		$('.c-header-menu--lvl-2').slideUp(400, 'swing');

		// Add class 'is-open'
		$(this).addClass('is-open');

		// Expand drop-down
		$(this).next('.c-header-menu--lvl-2').slideDown(400, 'swing');

	}

});
