/**
 |-----------------------------------------------------------------------------
 | vendor/slick.js
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

/*
 |
 | Some description...
 |
 */

require('slick-carousel');





/*
 |
 | Some description...
 |
 */

//if ($('[data-js="slick-gallery"]').length > 0) {

	$('[data-js="slick-gallery"]').slick({

		// Initialisation
		arrows         : ($('[data-js="slick-gallery"] > .o-picture').length > 1) ? true : false,
		autoplay       : true,
		autoplaySpeed  : 6000,
		dots           : false,
		draggable      : false,
		focusOnSelect  : true,
		infinite       : true,
		pauseOnFocus   : true,
		pauseOnHover   : true,
		rows           : 0,
		slide          : '.o-picture',
		slidesToShow   : 1,
		slidesToScroll : 1,
		speed          : 300

	});

//}





/*
 |
 | Some description...
 |
 */

if ($('.js-carousel--hero').length > 0) {

	$('.js-carousel--hero').slick({

		// Initialisation
		arrows         : ($('.js-carousel--hero > .c-banner').length > 1) ? true : false,
		autoplay       : true,
		autoplaySpeed  : 6000,
		dots           : false,
		draggable      : false,
		focusOnSelect  : true,
		infinite       : true,
		pauseOnFocus   : true,
		pauseOnHover   : true,
		rows           : 0,
		slide          : '.c-banner',
		slidesToShow   : 1,
		slidesToScroll : 1,
		speed          : 300,

		// Breakpoints
		responsive     : [
			{
				breakpoint : 415,
				settings   : {
					arrows       : false,
					dots         : ($('.js-carousel--hero > .c-banner').length > 1) ? true : false,
				}
			},
		],

	});
}


/*
 |
 | Some description...
 |
 */

if ($('.js-carousel--other-posts').length > 0) {

	$('.js-carousel--other-posts').slick({

		// Initialisation
		arrows         : ($('.js-carousel--other-posts > .c-post').length > 1) ? true : false,
		autoplay       : true,
		autoplaySpeed  : 6000,
		dots           : false,
		draggable      : false,
		focusOnSelect  : true,
		infinite       : true,
		pauseOnFocus   : true,
		pauseOnHover   : true,
		rows           : 0,
		slide          : '.c-post',
		slidesToShow   : 3,
		slidesToScroll : 1,
		speed          : 300,

		// Breakpoints
		responsive     : [
			{
				breakpoint : 769,
				settings   : {
					dots         : true,
					slidesToShow : 2
				}
			},
			{
				breakpoint : 415,
				settings   : {
					dots         : true,
					slidesToShow : 1
				}
			}
		],

	});
}


/*
 |
 | Some description...
 |
 */

if ($('.js-carousel--stay-informed').length > 0) {

	$('.js-carousel--stay-informed').slick({

		// Initialisation
		arrows         : false,
		autoplay       : false,
		autoplaySpeed  : 6000,
		dots           : ($('.js-carousel--stay-informed > .c-post').length > 1) ? true : false,
		draggable      : false,
		focusOnSelect  : true,
		infinite       : true,
		pauseOnFocus   : true,
		pauseOnHover   : true,
		rows           : 0,
		slide          : '.c-post',
		slidesToShow   : 3,
		slidesToScroll : 1,
		speed          : 300,

		// Breakpoints
		responsive     : [
			{
				breakpoint : 1025,
				settings   : {
					autoplay     : true,
					slidesToShow : 2
				}
			},
			{
				breakpoint : 415,
				settings   : {
					autoplay     : true,
					slidesToShow : 1
				}
			}
		],

	});
}


/*
 |
 | Some description...
 |
 */

if ($('.js-carousel--reviews').length > 0) {

	$('.js-carousel--reviews').slick({

		// Initialisation
		arrows         : false,
		autoplay       : true,
		autoplaySpeed  : 6000,
		dots           : ($('.js-carousel--reviews > .c-review').length > 1) ? true : false,
		draggable      : false,
		focusOnSelect  : true,
		infinite       : true,
		pauseOnFocus   : true,
		pauseOnHover   : true,
		rows           : 0,
		slide          : '.c-review',
		slidesToShow   : 1,
		slidesToScroll : 1,
		speed          : 300

	});
}
