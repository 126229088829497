/**
 |-----------------------------------------------------------------------------
 | vendor/headroom.js
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

/*
 |
 | Some description...
 |
 */

const Headroom = require('headroom.js');





/*
 |
 | Some description...
 |
 */

const header   = document.querySelector('[data-js="sticky-header"]');
const headroom = new Headroom(header, {

	// Initialisation
	offset    : 144,
	tolerance : {
		down : 0,
		up   : 0
	},

	// Classes
	classes   : {
		initial  : 'l-site-header',
		pinned   : 'is-sticky',
		unpinned : 'is-hidden'
	}

});
headroom.init();
