/**
 |-----------------------------------------------------------------------------
 | vendor/infinite-scroll.js
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

/*
 |
 | Some description...
 |
 */

const InfiniteScroll = require('infinite-scroll');





/*
 |
 | Some description...
 |
 */


const events = document.querySelector('[data-js="scroll-events"]');
const infScroll = new InfiniteScroll(events, {

	// Initialisation
	append: '.c-event',
	hideNav: '.c-pagination__more',
	history: 'push',
	path: '.c-pagination__more',
	status: '.c-pagination__status'

});
