/**
 |-----------------------------------------------------------------------------
 | custom/tabs.js
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

$('.js-tab').click(function () {

	// Some description...
	$(this).addClass('is-selected').siblings('[data-tab]').removeClass('is-selected');

	// Some description...
	$('[data-panel=' + $(this).data('tab') + ']').addClass('is-visible').siblings('[data-panel]').removeClass('is-visible');

});
