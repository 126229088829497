/**
 |-----------------------------------------------------------------------------
 | custom/modals.js
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

/*
 |
 | Some description...
 |
 */

$('.js-open-modal').on('click', function (e) {

	// Prevent default
	e.preventDefault();

	// Toggle classes
	$('#' + $(this).data('modal')).toggleClass('is-open');

	// Slick
	const slideIndex = $(this).index();
	$('[data-js="slick-gallery"]').slick('slickGoTo', slideIndex);

});


/*
 |
 | Some description...
 |
 */

$('[data-js="close-modal"]').on('click', function () {

	// Toggle classes
	$(this).parent().toggleClass('is-open');

});


/*
 |
 | Some description...
 |
 */

$(document).keyup(function(e) {

	if ((e.keyCode === 27) && ($('.c-modal').hasClass('is-open'))) {    // Escape key

		// Toggle classes
		$('.c-modal').removeClass('is-open');

	}

});
