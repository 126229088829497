/**
 |-----------------------------------------------------------------------------
 | main.js
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 | Contents
 |
 |  1. Vendor                                                              LN:1
 |  2. Custom                                                              LN:1
 |
 */

/*
 |-----------------------------------------------------------------------------
 | Vendor
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

window.$ = window.jQuery = require('jquery');

require('cookieconsent');
require('./vendor/headroom.js');
// require('imagesloaded');
require('./vendor/infinite-scroll.js');
// require('isotope-layout');
require('lazysizes');
require('./vendor/slick.js');





/*
 |-----------------------------------------------------------------------------
 | Custom
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

// require('./custom/alerts.js');
require('./custom/menu.js');
require('./custom/modals.js');
require('./custom/tabs.js');
